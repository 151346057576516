import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/trd.css';

loadCSS('trd-site');

export const TrdSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default TrdSiteWrapper;
